import data from './data.json'
const { allTimeMoneyChoices, fullTimeMoneyChoices, partTimeMoneyChoices, availabilityChoices } = data

export const questions = {
  availability: {
    prompt: 'How much do you want to work?',
    choices: (_) => {
      return availabilityChoices
    }
  },
  money: {
    prompt: 'What\'s the least amount of money you would accept?',
    choices: (answers) => {
      if (!answers.availability) { return partTimeMoneyChoices }
      if (
        answers.availability.includes('flexible') ||
        (answers.availability.includes('full-time') && answers.availability.includes('part-time'))
      ) {
        return allTimeMoneyChoices
      } else if (answers.availability.includes('full-time')) {
        return fullTimeMoneyChoices
      } else {
        return partTimeMoneyChoices
      }
    },
  },
  location: {
    prompt: 'What kind of working arrangement are you looking for?',
    choices: (_) => {
      return [
        'remote',
        'onsite',
        'hybrid',
      ]
    }
  },
  responsibilities: {
    prompt: 'What do you want to get better at?',
    type: 'select-many',
    allowCustomText: true,
    choices: (_) => {
      return [
        'programming',
        'open source',
        'managing/mentoring',
        'hiring',
        'design',
        'product',
        'talking to customers',
        'sales & marketing/growth',
        'nothing, stop bothering me',
      ]
    }
  },
  likes: {
    prompt: 'What is your specialty?',
    type: 'select-many',
    allowCustomText: true,
    choices: (_) => {
      return [
        'greenfield projects',
        'making slow code fast',
        'cleaning up messy code',
        'fixing hard bugs',
        'automating problems away',
      ]
    }
  },
  team: {
    prompt: 'What do you look for in a team?',
    allowCustomText: true,
    type: 'select-many',
    choices: (_) => {
      return [
        'Blameless/Safe Environment to Fail',
        'Team is Diverse',
        'Cross-Department Collaboration',
        'Open Communication',
        'EQ > IQ',
        'Flat Organization',
        'Wears Many Hats'
      ]
    }
  },
  health: {
    prompt: 'Which personal health values are important to you?',
    allowCustomText: true,
    type: 'select-many',
    choices: (_) => {
      return [
        'Actively Practices Inclusion',
        'Work/Life Balance',
        'Committed to Personal Growth',
        'Ideal for Parents',
        'Supports Physical Wellness',
      ]
    }
  },
  career: {
    prompt: 'Which career growth values are important to you?',
    allowCustomText: true,
    type: 'select-many',
    choices: (_) => {
      return [
        'Internal Mobility',
        'Mentorship Program',
        'Good for Junior Devs',
        'Offers Internships',
        'High Employee Retention',
        'Rapidly Growing Team',
        'Ownership'
      ]
    }
  },
  engineering: {
    prompt: 'What engineering values are important to you?',
    allowCustomText: true,
    type: 'select-many',
    choices: (_) => {
      return [
        'Pair Programs',
        'Writes Tests',
        'Open Source',
        'Start-to-Finish Ownership',
        'Uses Agile',
        'Cutting-Edge Technologies',
        'Fast-Paced Environment',
        'Continuous Delivery'
      ]
    }
  },
  philosophy: {
    prompt: 'What company philosophies do you like?',
    allowCustomText: true,
    type: 'select-many',
    choices: (_) => {
      return [
        'Customer Comes First',
        'Engineering-Driven',
        'Product-Driven',
        'Design-Driven',
        'Data-Driven',
      ]
    }
  },
  size: {
    prompt: 'What size company do you like?',
    type: 'select-many',
    choices: (_) => {
      return [
        'startup (0-50 people)',
        'medium (50-200 people)',
        'big (200-1000 people)',
        'mondo (1000+ people)',
      ]
    }
  },
  benefits: {
    prompt: 'Which uncommon benefits are you interested in?',
    allowCustomText: true,
    type: 'select-many',
    choices: (_) => {
      return [
        'equity',
        'options',
        '100% healthcare coverage',
        'h1b visa',
        'home office stipend',
        'mandatory vacation',
        '4-day work week',
        'company retreats',
        'light meetings',
        'no daily calls',
      ]
    }
  },
  accomplishments: {
    prompt: 'Why should people hire you?',
    type: 'select-many',
    allowCustomText: true,
    choices: (_) => {
      return [
        "built something great",
        "worked on a team",
        "shipped production code",
        "CS degree",
        "freelanced",
        "scaled systems",
        "core contributor to popular repo",
        "speaker / writer / thought-leader",
        "raised funding",
        "Sold Company",
        "Took company public",
        "award winner",
        "attended bootcamp",
        "Founded profitable company",
        "Founded a company",
        "worked at a startup",
        "has been C-level or VP",
        "built / led / managed teams",
        "Released a successful game",
        "founded a game studio", "AAA", "built an engine",
        "Built a game", "worked at a game studio", "contributed to an engine",
        // "Released hardware product", "founded hardware company", "scaled manufacturing and distribution",
        // "worked on hardware product", "designed hardware product",
        // "Hardware Engineering Education",
        "Awarded Patent", "Published Research",
        "advanced science", "created popular tool", "scientific work experience",
        "has advanced degree",
      ]
    }
  },
  // dealbreakers: {
  //   prompt: 'What are your dealbreakers?',
  //   choices: (_) => {
  //     return [
  //       '',
  //     ]
  //   }
  // },
  year_started_programming: {
    prompt: 'What year did you start programming professionally?',
    type: 'text',
    placeholder: '2015',
  },
  name: {
    prompt: 'What is your username?',
    note: 'We don\'t need your real name so please don\'t give it to us.',
    type: 'text',
    placeholder: 'username',
    omitFromProgress: true,
  },
  email: {
    prompt: 'Where should we send jobs that meet your criteria?',
    note: 'We will never share your email with anyone. Only you can decide to reveal your email to an employer.',
    type: 'email',
    placeholder: 'name@domain.com',
    omitFromProgress: true,
  }
}

export const order = Object.keys(questions)
