import { useEffect, useRef, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'

import Questions from '../components/questions'
import Header from '../components/header'
import Flash from '../components/flash'
import Meta from '../components/meta'
import MovedByNav from '../components/movedByNav'
import Nav from '../components/nav'
import QuestionnairePrompt from '../components/questionnairePrompt'
import { questions, order } from '../questions/onboarding'
import { currentUserSelector } from '../state/selectors'
import { answersState, questionsOrderState, flashState } from '../state/atoms'
import api, { get } from '../lib/api'
import { Container, Title } from '../styles/shared.css'
import { breakpoints } from '../styles/shared.css'
import { filterObject, makeSubmitOnboardingPayload } from '../lib/helpers'

const HomepageContainer = styled.div`
  padding: 100px 0 0;

  @media (max-width: ${breakpoints.small}) {
    padding : 0;
  }
`

const Hero = styled.div`
  margin: 40px 0 0;

  h1 {
    margin-bottom: 15px;
  }

  @media (max-width: ${breakpoints.small}) {
    margin: 20px 0 0;
  }
`

export default function Home() {
  const currentUser = useRecoilValue(currentUserSelector)
  const setQuestionsOrder = useSetRecoilState(questionsOrderState)
  const setAnswers = useSetRecoilState(answersState)
  const setFlash = useSetRecoilState(flashState)
  const stickyNav = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => { setQuestionsOrder(order) }, [setQuestionsOrder])

  useEffect(() => {
    if (!currentUser) {
      return
    }

    const fetchProfile = async () => {
      const res = await get('me')
      const data = await res.json()
      const answers = filterObject(data, (k, _) => Object.keys(questions).includes(k))
      setAnswers(answers)
    }
    fetchProfile()
  }, [currentUser, setAnswers])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.toggleAttribute('stuck', (e.intersectionRatio < 1 && e.boundingClientRect.x == 0))
      },
      { threshold: [1] }
    )
    observer.observe(stickyNav.current)
  }, [])

  const handleSubmit = async (questions, answers) => {
    // NOTE if we change this method we also have to change the same
    // handler on the user profile too (they can sign up from there as well)

    if (isSubmitting) { return }

    setIsSubmitting(true)
    const payload = makeSubmitOnboardingPayload(questions, answers)
    const path = currentUser ? `users/${currentUser.id}` : 'users'
    const method = currentUser ? 'PATCH' : 'POST'
    const flashAction = currentUser ? 'updated' : 'created'
    const request = await api(path, payload, method)
    const response = await request.json()
    setIsSubmitting(false)

    if (request.status == 200) {
      setFlash(`<em>Your profile has been ${flashAction}!</em> We'll email you when we find jobs you'll like.`)
      if (!currentUser) {
        localStorage.setItem(`polyfill-${response.id}`, new Date().getTime())
      }
      window.location = `/${response.id}`
    } else if (request.status == 401) {
      alert("A user with that email address already exists.")
    } else {
      alert(JSON.stringify(response))
    }
  }

  const isEditing = !!currentUser

  return <>
    <Meta />
    <MovedByNav>
      <HomepageContainer>
        <Header ref={stickyNav} />
        <Flash />
        <Container>
          <Hero>
            <Title>Find a developer job you actually want.</Title>
            <QuestionnairePrompt />
          </Hero>
          <Questions
            questions={questions}
            pageName='home'
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submitButtonLabel={isEditing ? 'Update Account' : 'Create Account'}
            isEditing={isEditing}
          />
        </Container>
      </HomepageContainer>
    </MovedByNav>
    <Nav />
  </>
}

Home.getLayout = function getLayout(page) {
  return <>{page}</>
}